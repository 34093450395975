// styles.scss
.testing {width: percentage(6/7);}

//Khai báo các biến
$main_color: #ED3338;
$second_color: #EC1C24;
$three_color: #C4161C;
$font_title: 'Josefin Sans', sans-serif;

@mixin transition_3s {
	transition: all .3s;
	-webkit-transition: all .3s;
	-moz-transition: all .3s;
}

@mixin transition_5s {
	transition: all .5s;
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
}

@mixin radius_2 {
	border-radius: 2px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
}

//Khai báo font
@font-face {
    font-family: UTM Avo;
    src: url("../../font/UTM Avo.ttf");
}

@font-face {
    font-family: UTM AvoBold;
    src: url("../../font/UTM AvoBold.ttf");
}

.taskbar-m {
	background: $main_color;
}

.logo_mobile {
	.container {
		.logo_m {
			text-align: center;
    		padding: 10px 0;
    		a {
    			img {
    				width: 160px;
    			}
    		}
		}
		.mobile_search {
			form {
				margin: 0 20px 20px;
				input {
					width: calc(100% - 32px);
				    color: #333;
				    line-height: 32px;
				    text-indent: 10px;
				    outline: none;
				    top: 0;
				    right: 32px;
				    border: 1px solid #ddd;
				    transition: all .3s;
				    -webkit-transition: all .3s;
				}
				button {
					float: right;
				    width: 32px;
				    text-align: center;
				    background: transparent;
				    background: $main_color;
				    outline: none;
				    border-left: 0;
				    transition: all .3s;
				    -webkit-transition: all .3s;
				    i {
				    	color: #fff; 
				    	line-height: 34px; 
				    	font-size: 16px;
				    }
				}
			}
		}
	}
}

.top {
	height: 96px;
	background: #FFF;
	color: #000;
	.container {
		.row {
			border-bottom: 1px solid #E1E1E1;
			.left {
				width: 40%;
				span {
					line-height: 95px;
    				font-size: 13px;					
					i {
						color: $main_color;
						margin-right: 10px;
					}
					strong {
						a:hover {color: $main_color;}
					}
				}
				span:last-child {
					padding-left: 10px;
				    margin-left: 10px;
				    border-left: 1px solid;}
			}
			.logo {
				width: 20%;
				text-align: center;
				a {
					img {
						width: 160px;
						height: 60px;
						margin-top: 18px;
					}
				}
			}
			.right {
				width: 40%;
				.wrap_shop {
					background: url(../images/shopping-cart.png) no-repeat bottom;
					background-size: 25px;
					margin-top: 20px;
					padding-bottom: 22px;
					.shop-cart {
						width: 16px;
					    height: 16px;
					    line-height: 16px;
					    right: 5px;
					    background: $main_color;
					}
					.shop-cart:hover {background: #000;}
				}
				ul {
					float: right;
					li {
						line-height: 95px;
						a {				
							font-size: 13px;			
							display: block;
    						padding-right: 10px;
    						padding-left: 25px;
    						background: url(../images/icon_menu1.png) no-repeat left;
						}
						a:hover {color: $main_color;}
					}
				}
			}
		}
	}
}

.top_menu {
	height: unset;
	overflow: unset;
	.container {
		.row {
			.menu_main {
				float: none;
    			max-width: unset;
    			height: 52px;
    			background: #FFF;
				>ul {
					float: none;
					text-align: center;
					>li {
						margin: 0;
						display: -webkit-inline-box;
						float: none;
						a {
							line-height: 52px;
							height: 50px;
							color: #000;
							padding: 0 28px;
						}						
						ul {
							top: 52px;
							left: 0;
    						text-align: left;
							li {
								a {
									line-height: 42px;
								    height: 42px;
								    color: #000;
								}
								a:hover {
									color: #FFF;
									background: $main_color;
								}
							}
						}
					}
					li:hover {
						> a {color: $main_color;}
					}
					li.active {
						> a {color: $main_color;}
					}

				}
			}
		}
	}
}
.top_menu.fix_menu {height: 54px;
	border-bottom: 2px solid $main_color;}

.slider_box {
	.flexslider {
		ul {
			li {
				.container {
					.more_div {
						width: 585px;
    					right: calc(50% - 585px);
    					padding-left: 50px;
    					h2 {
    						font-size: 36px;
    						line-height: 50px;
    						margin-top: 0;
    						font-family: "Roboto Condensed", sans-serif;
    						a {
    							color: $main_color;
    							span {
    								font-size: 36px;
    							}
    						}
    					}
    					p {
    						margin: 30px 0 30px 120px;
    						font-weight: normal;
    						font-style: normal;
    						font-size: 21px;
    					}
    					p::before {
    						content: "\f046";
						    font-family: FontAwesome;
						    color: $main_color;
						    font-size: 20px;
						    margin-right: 15px;
    					}

    					.more {
    						background: $main_color;
    						font-size: 20px;
    						padding: 10px 35px;
    						font-weight: 600;
    						margin-top: 25px;
    						text-transform: uppercase;
    						float: right;
    						border: 2px solid;
						    border-radius: 30px;
						    -webkit-border-radius: 30px;
						    -moz-border-radius: 30px;
    					}
    					.more:hover {
    						background: #FFF;
    						color: $main_color;
    					}
					}
				}
			}
		}

		.flex-control-paging {
			li {
				a:hover {
					background: #FFF !important;
				}
				a.flex-active {
					background: #FFF !important;
				}
			}
		}

		ul.flex-direction-nav {
			li {
				a {
					color: $main_color;
				}
			}
		}
	}
}

/* intro */
.intro {
	padding: 50px 0;
	.title {
		text-align: center;
		margin-bottom: 100px;
		p {
			display: inline-block;
			text-transform: uppercase;
			font-size: 30px;
			padding: 40px 45px 0 0;
			color: $second_color;
			font-family: "UTM AvoBold" ,sans-serif;
			background: url(../images/bg_title.png) no-repeat right top;
			span {
				font-family: "UTM Avo" ,sans-serif;
				color: #000;
			}
		}
	}
	.container {
		.row {
			.item {
				dl {
					position: relative;
					background: $main_color;
					dt {
						position: absolute;
						top: -40px;
    					left: 40px;
						border: 5px solid #EBEBEB;
						background: $main_color;
						img {
							width: 75px;
							@include transition_5s;
						}
					}
					dd {
						padding: 80px 40px 45px;
						h3 {
							font-size: 23px;
							font-family: "Roboto Condensed", sans-serif;
							color: #FFF;
							margin-bottom: 25px;
						}
						p.brief {
							font-size: 15px;
						    color: #FFF;
						    line-height: 22px;
						    margin-bottom: 25px;
						    height: 132px;
    						overflow: hidden;
						}
						a.more {
							@include transition_3s;
							float: right;
							float: right;
						    border: none;
						    padding: unset;
						    font-weight: bold;
						    font-style: italic;
						    i {@include transition_3s;}
						}
						a.more:hover {background: $main_color;}
						a.more:hover {
							i {margin-left: 5px;}
						}
					}
				}
				dl:hover {
					dt {
						img {
							-webkit-transform: scale(1.2);
						    -moz-transform: scale(1.2);
						    -ms-transform: scale(1.2);
						    -o-transform: scale(1.2);
						    transform: scale(1.2);
						}
					}
				}
			}
			.item:nth-child(3n+1) {padding-right: 10px;}
			.item:nth-child(3n+2) {padding-left: 5px;padding-right: 5px;}
			.item:nth-child(3n+3) {padding-left: 10px;}
		}
	}
}

/* đại lý + tại sao */
.agency_why {
	padding: 50px 0;
    background: #F5F5F5;
    .container {
    	.row {
    		.agency {
    			border: 10px solid #E1E1E1;
    			div {
    				background: #FFF;
    				padding: 30px;
    				.title {
						font-size: 15px;
    					padding-left: 35px;
    					background: url(../images/bg_daily.png) no-repeat left top;
    					margin-bottom: 10px;
    				}
    				ul {
    					li {
    						border-bottom: dashed 1px $main_color;
    						line-height: 36px;
    						span {
    							font-size: 15px;
    						}
    						span.amount {float: right;}
    					}
    				}
    			}
				.hotline {
					background: $main_color;
					color: #FFF;
					.title {
						padding-left: 0px;
    					background: unset;
    					color: #FFF;
    					text-align: center;
					}
					.phone {
						font-size: 36px;
						font-weight: bold;
						font-family: "Roboto Condensed", sans-serif;
						background: url(../images/phone.png) no-repeat left top;
						padding-left: 60px;
						line-height: 50px;
						height: 50px;
						display: block;
						a {
							color: #FFF;
						}
						a:hover {
							color: #000;
						}
					}
					.swing {
				    	position: relative;
						transition:all 0.3s ease;
				    }
				    .swing:hover {
					    -webkit-animation: swingg 1s ease;
					    animation: swingg 1s ease;
					    -webkit-animation-iteration-count: 1;
					    animation-iteration-count: 1;
					}
				}
    		}
    		.why {
    			padding-left: 60px;
    			.title {
    				margin-bottom: 25px;
    				display: inline-block;
					text-transform: uppercase;
					font-size: 30px;
					padding: 40px 45px 0 0;
					color: $second_color;
					font-family: "UTM AvoBold" ,sans-serif;
					background: url(../images/bg_title.png) no-repeat right top;
					span {
						font-family: "UTM Avo" ,sans-serif;
						color: #000;
					}
    			}
    			>.brief {
    				font-size: 15px;
				    line-height: 24px;
				    margin-bottom: 25px;
    			}
    			.row {
    				.item {
    					margin-bottom: 35px;
    					dl {
    						dt {
    							width: 60px;
    							float: left;
    							img {width: 100%;}
    						}
    						dd {
    							width: calc(100% - 80px);
    							float: right;
    							h3 {
    								font-size: 15px;
    								text-transform: uppercase;
    								margin-bottom: 10px;
    							}
    							.brief {
									line-height: 22px;
    								font-size: 15px;
    							}
    						}
    					}
    				}
    				.item:nth-child(2n+1) {padding-right: 10px;}
    				.item:nth-child(2n+2) {padding-left: 10px;}
    			}
    		}
    	}
    }
}

/* sản phẩm */
.cat_home {
	padding: 50px 0;
	background: unset;
	.title {
		text-align: center;
		margin-bottom: 40px;
		p {			
			display: inline-block;
			text-transform: uppercase;
			font-size: 30px;
			padding: 40px 45px 0 0;
			color: $second_color;
			font-family: "UTM AvoBold" ,sans-serif;
			background: url(../images/bg_title.png) no-repeat right top;
			span {
				font-family: "UTM Avo" ,sans-serif;
				color: #000;
			}
		}
	}
	.brief {
		text-align: center;
		font-size: 15px;
		line-height: 22px;
		margin-bottom: 50px;
	}
	.container {
		.list_title {
			background: #FFF;
			ul {
				display: -webkit-inline-box;
    			width: 100%;
    			text-align: center;
    			border: 1px solid #CCC;
				li {
					width: 25%;					
					border-right: 1px solid #CCC;
					a {
						display: block;
						padding: 150px 0 40px;
						font-size: 15px;
    					font-weight: bold;
					}
					a:hover {color: $main_color;}
					
				}
				li:last-child {border-right: none;}
				li:nth-child(1) {
					a.active {
						background: url(../images/cat_icon12.png) no-repeat center $main_color !important;
						color: #FFF;
					}
				}
				li:nth-child(2) {
					a.active {
						background: url(../images/cat_icon22.png) no-repeat center $main_color !important;
						color: #FFF;
					}
				}
				li:nth-child(3) {
					a.active {
						background: url(../images/cat_icon32.png) no-repeat center $main_color !important;
						color: #FFF;
					}
				}
				li:nth-child(4) {
					a.active {
						background: url(../images/cat_icon42.png) no-repeat center $main_color !important;
						color: #FFF;
					}
				}
			}
		}
		.listproduct {
			background: #FFF;
			border: 1px solid #CCC;
			border-top: none;
			.product_flex {
				width: 50%;
				margin: 0;
				padding: 50px;
				ul.slides {
					li {
						padding: 90px 0;
						dl {
							width: 80%;
    						position: relative;
							dt {
								padding-right: 20px;
								h3 {
									font-family: "UTM AvoBold" ,sans-serif;
									font-size: 38px;
    								margin-bottom: 25px;
									a {
										color: $main_color;
									}
								}
								p {
									font-size: 15px;
								    line-height: 22px;
								    font-weight: normal;
								}
							}
							dd {
								position: absolute;
							    width: 80%;
							    right: -80%;
							    top: 0;
							    .swing {
							    	position: relative;
    								transition:all 0.3s ease;
							    	a {
							    		img {
							    			width: 100%;
							    		}
							    	}
							    }
							    .swing:hover {
								    -webkit-animation: swingg 1s ease;
								    animation: swingg 1s ease;
								    -webkit-animation-iteration-count: 1;
								    animation-iteration-count: 1;
								}
							}
						}
					}
				}
				ol.flex-control-nav {
					li {
						a {
							border-radius: unset;
						}
					}
				}
			}
			.image {
				width: 50%;
				margin: 0;
				padding: 25px;
				img {
					width: 100%;
				}
			}
		}
	}
}

/* khach hang */
.customer {
	padding: 50px 0 70px;
	.title {
		text-align: center;
		margin-bottom: 50px;
		p {			
			display: inline-block;
			text-transform: uppercase;
			font-size: 30px;
			padding: 40px 45px 0 0;
			color: #FFF;
			font-family: "UTM AvoBold" ,sans-serif;
			background: url(../images/bg_title2.png) no-repeat right top;
			span {
				font-family: "UTM Avo" ,sans-serif;
				color: #FFF;
			}
		}
	}
	.container {
		text-align: center;
		.customer_flex {
			position: relative;
			ul.slides {
				li {
					div {
						dl {
							dt {
								margin-bottom: 20px;
								img {
									width: 100px;
								    border-radius: 50px;
								    border: 3px solid #FFF;
								}
							}
							dd {
								span {
									font-size: 20px;
									color: #FFF;
									font-weight: bold;
									text-transform: uppercase;
								    margin-bottom: 10px;
								    display: block;
								}
								p {
									color: #FFF;
								    font-size: 15px;
								    line-height: 24px;
								}
							}
						}
					}
				}
			}
			ol.flex-control-nav {
				display: none;
			}
			ul.flex-direction-nav {
				li {
					a::before {
						content: '';
					}
					a.flex-prev {
						opacity: 1;
						top: unset;
						bottom: -40px;
    					left: calc(50% - 40px);
    					background: url(../images/oprev.png) no-repeat;
					}
					a.flex-next {
						opacity: 1;
						top: unset;
						bottom: -40px;
    					right: calc(50% - 40px);
    					background: url(../images/onext.png) no-repeat;
					}
				}
			}
		}
	}
}

/* tin tuc */
.news_home {
	padding: 50px 0;
	background: #F5F5F5;
	.title {
		text-align: center;
		margin-bottom: 50px;
		border: unset;
		p {			
			display: inline-block;
			text-transform: uppercase;
			font-size: 30px;
			padding: 40px 45px 0 0;
			color: $second_color;
			font-family: "UTM AvoBold" ,sans-serif;
			background: url(../images/bg_title.png) no-repeat right top;
			span {
				font-family: "UTM Avo" ,sans-serif;
				color: #000;
			}
		}
	}
	.container {
		.row {
			margin-bottom: 0;
			.item {
				dl {
					border-radius: 5px;
					dt {
						width: unset;
						float: none;
					}
					dd {
						width: unset;
						float: none;
						padding: 0;
						.name {
							text-align: center;
							font-size: 15px;
    						text-transform: uppercase;
    						font-weight: bold;
    						margin: 30px 30px 20px;
    						a:hover {
    							color: $main_color;
    						}
						}
						.brief {
							text-align: center;
							margin: 0 20px 20px;
						}
						.info {
							border-top: 1px solid #e1e1e1;
							>span {
								line-height: 45px;
							    font-size: 13px;
							    font-family: "Roboto", sans-serif;
							    font-style: italic;
							    width: calc(50% - 20px);
							}
							.date {
								float: left;
							    text-align: left;
							    padding-left: 30px;
							    margin-left: 20px;
							    background: url(../images/icon_lich.png) no-repeat center left;
							    border-right: 1px solid #e1e1e1;
							}
							.cate {
								float: left;
							    text-align: left;
							    padding-left: 30px;
							    margin-left: 20px;
							    background: url(../images/icon_new.png) no-repeat center left;
							}
						}
					}
				}
			}
			.item:nth-child(3n+1) {padding-right: 14px;}
			.item:nth-child(3n+2) {padding-right: 7px;padding-left: 7px;}
			.item:nth-child(3n+3) {padding-left: 14px;}
		}
	}
}

/* chân site */
.footer {
	padding: 50px 0 0;
	background: $three_color;
	.container {
		.top {
			background: unset;
			border-bottom: 1px solid #a01212;
			>div {
				text-align: center;
				i {
					font-size: 18px;
				    width: 30px;
				    height: 30px;
				    border: 2px solid;
				    border-radius: 15px;
				    padding-top: 5px;
				    margin-bottom: 20px;
				}
			}
		}
		.bottom {
			border-bottom: 1px solid #a01212;
			>div {
				h3 {
					font-size: 22px;
				    text-transform: uppercase;
				    margin-bottom: 20px;
				}
			}
			.menu_footer {
				>div {
					ul {
						li {
							line-height: 30px;
							a {
								@include transition_5s;
								i {
									color: $three_color;
								}
							}
							a:hover {
								padding-left: 5px;
								color: #000;
								i {
									color: #FFF;
								}
							}
						}
					}
				}
			}
			.partner_footer {
				a {
					float: none;
				}
			}
		}
		.bottom_page {
			.item {
				p {
					color: #FFF;
					font-weight: bold;
					a:hover {color: #000;}
				}
			}
			.go-top {
				opacity: 0;
				position: fixed;
			    bottom: 10px;
			    right: 10px;
				i {
					background: #000;
    				color: #FFF;
    				font-size: 16px;
    				border-radius: 25px;
    				padding: 10px;
				}
			}
			.go-top.fix_go {
				opacity: 1;
			}
		}
	}
}

/* các trang con */

/* cột trái tìm kiếm */
.left {
	.left_n {
		.search_left {
			padding: 10px;
			.wrap_form {
				position: relative;
				.position {
					top: 48px;;
					>p {
						padding: 10px;
						background: unset;
						color: $main_color;
					}
					>div {
						width: 100%;
						padding: 10px;
						.s_img {
							width: 90px;
						}
					}
				}
				.frs {
					box-shadow: none;
				}
			}
		}
		.menu_left {
			>ul {
				>li {
					a {}
					a:hover {
						color: $main_color;
					}
				}
			}
		}
	}
}

/* trang sản phẩm */
.listproduct {
	>.item {
		>.box_p {
			>.scale {
				img {
					max-width: 90%;
    				max-height: 90%;
    				@include transition_5s;
				}
			}
			>.scale:hover {
				img {
					-webkit-transform: scale(1.05);
				    -moz-transform: scale(1.05);
				    -ms-transform: scale(1.05);
				    -o-transform: scale(1.05);
				    transform: scale(1.05);
				}
			}
		}
		.box_p:hover {
			box-shadow: 0 0 10px 0 $main_color;
		    -webkit-box-shadow: 0 0 10px 0 $main_color;
		    -moz-box-shadow: 0 0 10px 0 $main_color;
		}
	}
}
.content {
	.container {
		.product_subpage {
			.product_page {
				.right_p {
					.brief_ppage {
						.shopcart_form {
							.form_row {
								.button_cart {
									button {
										background: -webkit-gradient(linear, left top, left bottom, from($main_color), to($three_color));
    									background: linear-gradient(to bottom, $three_color, $main_color)
									}
								}
							}
						}
					}
				}
			}
		}
		.policy {
			padding-bottom: 0 !important;
			.hotline {
				padding: 10px;
				background: $main_color;
				color: #FFF;
				.title {
					font-size: 16px;
					padding-left: 0px;
					background: unset;
					color: #FFF;
					text-align: center;
				}
				.phone {
					font-size: 32px;
					font-weight: bold;
					font-family: "Roboto Condensed", sans-serif;
					background: url(../images/phone.png) no-repeat left top;
					padding-left: 60px;
					line-height: 50px;
					height: 50px;
					display: block;
					a {
						color: #FFF;
					}
					a:hover {
						color: #000;
					}
				}
				.swing {
			    	position: relative;
					transition:all 0.3s ease;
			    }
			    .swing:hover {
				    -webkit-animation: swingg 1s ease;
				    animation: swingg 1s ease;
				    -webkit-animation-iteration-count: 1;
				    animation-iteration-count: 1;
				}
			}
		}
	}
}

/* mobile trang chủ */

@media screen and (max-width: 599px) {
	
	.slider_box {
		.flexslider {
			ul {
				li {
					.container {
						.more_div {
							display: none;
						}
					}
				}
			}
		}
	}

	.intro {
		padding: 30px 10px;
		.title {
			margin-bottom: 60px;
			p {
				font-size: 22px;
				padding: 30px 35px 0 0;
				background-size: 40px;
				span {
					font-family: "UTM Avo" ,sans-serif;
					color: #000;
				}
			}
		}
		.container {
			padding: 0;
			.row {
				.item {
					padding: 0 !important;
					margin-bottom: 40px;
					dl {
						position: relative;
						dt {
							top: -30px;
	    					left: 20px;
							img {width: 50px;}
						}
						dd {
							padding: 50px 20px 20px;
							h3 {
								margin-bottom: 15px;
							}
							p.brief {
							    margin-bottom: 15px;
							}
						}
					}
				}
				.item:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	/* đại lý + tại sao */
	.agency_why {
		padding: 30px 10px;
	    .container {
	    	padding: 0;
	    	.row {
	    		.agency {
	    		}
	    		.why {
	    			padding-left: 0;
	    			.title {
	    				margin-bottom: 25px;
						font-size: 22px;
						padding: 30px 35px 0 0;
						background-size: 40px;
	    			}
	    			>.brief {
	    				font-size: 15px;
					    line-height: 24px;
					    margin-bottom: 25px;
	    			}
	    			.row {
	    				.item {
	    					padding: 0 !important;
	    				}
	    				.item:last-child {
	    					margin-bottom: 0;
	    				}
	    			}
	    		}
	    	}
	    }
	}

	/* sản phẩm */
	.cat_home {
		padding: 30px 10px;
		.title {
			margin-bottom: 25px;
			p {	
				font-size: 22px;
				padding: 30px 35px 0 0;
				color: $second_color;
				background-size: 40px;
			}
		}
		.brief {
			margin-bottom: 25px;
		}
		.container {
			padding: 0;
			.list_title {
				background: #FFF;
				ul {
					display: block;
					li {
						width: 100%;					
						border-right: unset;
						border-bottom: 1px solid #CCC;
						a {
							padding: 40px 0 10px;
							background-size: 30px !important;
							background-position-y: top !important;
						}
						a:hover {color: $main_color;}						
					}
					li:last-child {
						border-bottom: none;
					}
					li:nth-child(1) {
						a.active {
							background-size: 30px !important;
							background-position-y: top !important;
						}
					}
					li:nth-child(2) {
						a.active {
							background-size: 30px !important;
							background-position-y: top !important;
						}
					}
					li:nth-child(3) {
						a.active {
							background-size: 30px !important;
							background-position-y: top !important;
						}
					}
					li:nth-child(4) {
						a.active {
							background-size: 30px !important;
							background-position-y: top !important;
						}
					}
					
				}
			}
			.listproduct {
				.product_flex {
					width: 100%;
					margin: 0;
					padding: 10px;
					ul.slides {
						li {
							padding: 30px 0;
							dl {
								width: 100%;
								dt {
									padding-right: 0;
									h3 {
										font-size: 25px;
	    								margin-bottom: 25px;
									}
								}
								dd {
									position: relative;
								    width: 80%;
								    margin: 0 auto;
								    top: 20px;
    								right: 0;
								}
							}
						}
					}
					ul.flex-direction-nav {
						display: none;
					}
				}
				.image {
					width: 100%;
					padding: 10px;
				}
			}
		}
	}

	/* khach hang */
	.customer {
		padding: 30px 0;
		.title {
			margin-bottom: 25px;
			p {
				font-size: 25px;
				line-height: 36px;
				padding: 30px 35px 0 0;
				background-size: 40px;
			}
		}
		.container {
			text-align: center;
			.customer_flex {
				position: relative;				
				ul.flex-direction-nav {
					li {
						a {
							top: unset !important;
							bottom: -30px !important;
						}
					}
				}
			}
		}
	}

	/* tin tuc */
	.news_home {
		padding: 30px 10px;
		.title {
			margin-bottom: 25px;
			p {	
				font-size: 22px;
				padding: 30px 35px 0 0;
				background-size: 40px;
			}
		}
		.container {
			padding: 0;
			.row {
				.item {
					margin-bottom: 10px;
				}
				.item:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	/* chân site */
	.footer {
		padding: 30px 10px 0;
		.container {
			padding: 0;
			.top {
			}
			.bottom {
				border-bottom: 1px solid #a01212;
				>div {
					h3 {
					    margin-bottom: 10px;
					}
				}
				.menu_footer {
					margin-bottom: 20px;
				}
				.partner_footer {
					a {
						float: none;
					}
				}
			}
			.bottom_page {
				.item {
					p {
						color: #FFF;
						font-weight: bold;
						a:hover {color: #000;}
					}
				}
				.go-top {
					i {
						background: #000;
	    				color: #FFF;
					}
				}
			}
		}
	}

}
